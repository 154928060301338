import React from 'react'
import * as SelectPrimitive from '@radix-ui/react-select'
import { Check, ChevronDown } from 'react-feather'
import './select.css'

// const RootStyles = {
//   display: 'flex',
//   flexDirection: 'column',
// }

const TriggerStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: 'var(--radii-2)',
  padding: '0 15px',
  fontSize: 'var(--fontSizes-3)',
  lineHeight: 1,
  height: 35,
  gap: 5,
  backgroundColor: 'white',
  color: 'var(--colors-mauve12)',
  fontFamily: 'roboto',
  border: '1.5px solid var(--colors-mauve7)',
}

const ContentStyles = {
  fontFamily: 'roboto',
  overflow: 'hidden',
  backgroundColor: 'white',
  borderRadius: 6,
  boxShadow:
    '0px 10px 38px -10px rgba(22, 23, 24, 0.35), 0px 10px 20px -15px rgba(22, 23, 24, 0.2)',
}

const ViewportStyles = {
  padding: 5,
}

const ItemStyles = {
  fontSize: 'var(--fontSizes-3)',
  lineHeight: 'var(--fontSizes-5)',
  borderRadius: 'var(--radii-2)',
  display: 'flex',
  alignItems: 'center',
  height: 'var(--space-6)',
  paddingLeft: '25px',
  position: 'relative',
  userSelect: 'none',
}

const IndicatorStyles = {
  position: 'absolute',
  left: 0,
  width: '25px',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
}

export const Select = (props) => {
  return (
    <SelectPrimitive.Root onValueChange={props.onValueChange} defaultValue={props.defaultValue}>
      <SelectPrimitive.Trigger style={{ ...TriggerStyles, ...props.style }} aria-label="Tier">
        <SelectPrimitive.Value />
        <SelectPrimitive.Icon>
          <ChevronDown color="var(--colors-orange8)" size={15} />
        </SelectPrimitive.Icon>
      </SelectPrimitive.Trigger>
      <SelectPrimitive.Content style={ContentStyles}>
        <SelectPrimitive.Viewport style={ViewportStyles}>
          <SelectPrimitive.Group>
            {props.options.map((option, index) => (
              <SelectPrimitive.Item
                key={index}
                value={option.value}
                style={{ ...ItemStyles, color: option.color || 'var(--colors-mauve12)' }}
                className="selectItem">
                <SelectPrimitive.ItemText>{option.label}</SelectPrimitive.ItemText>
                <SelectPrimitive.ItemIndicator style={IndicatorStyles}>
                  <Check color="var(--colors-orange8)" size={15} />
                </SelectPrimitive.ItemIndicator>
              </SelectPrimitive.Item>
            ))}
          </SelectPrimitive.Group>
        </SelectPrimitive.Viewport>
      </SelectPrimitive.Content>
    </SelectPrimitive.Root>
  )
}

export default Select
