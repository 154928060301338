import React from 'react'
import Page from '../templates/components/Page'
import ReviewsPage from '../templates/reviews/ReviewsTemplate'

const Reviews = () => {
  return (
    <Page>
      <ReviewsPage />
    </Page>
  )
}

export default Reviews
