import React, { useState, useEffect, ReactChild } from 'react'
import Card from '../components/Card'
import { LocationGroups, Restaurant, TierRange } from '../../globals.d'
import { isTierMatch, TierSelectOptions } from '../../utils/tiers.utils'
import Select from '../../components/select'
import classNames from 'classnames'
import './ReviewsTemplate.scss'

const ReviewsTemplate = () => {
  const [restaurants, setRestaurants] = useState<Restaurant[]>([])
  const [tierFilter, setTierFilter] = useState<TierRange | null>(null)
  const [locationFilter, setLocationFilter] = useState<string | null>(null)

  useEffect(() => {
    fetch(process.env.GATSBY_API_URL + 'fetchDatabase')
      .then((res) => res.json())
      .then((result: Restaurant[]) => {
        setRestaurants(result)
      })
  }, [])

  const sortValues = (val: string, type: string) => {
    if (type === 'location') {
      setLocationFilter(val)
    } else if (type === 'tier') {
      setTierFilter(val)
    }
  }

  // parse locations for location select options
  const locationOptions = Object.keys(LocationGroups).map((locationGroup) => {
    return { label: locationGroup, value: locationGroup }
  })
  locationOptions.unshift({ label: 'All Locations', value: 'All' })

  // filter restaurants by selected tier option filter
  var filteredRestaurants = [...restaurants]
  filteredRestaurants = filteredRestaurants.filter((res) => {
    if (locationFilter === null || locationFilter === 'All') {
      return true
    }
    const locGroup = LocationGroups[locationFilter]
    return locGroup.includes(res.location)
  })
  filteredRestaurants = filteredRestaurants.filter((res) => isTierMatch(res.tier, tierFilter))

  let restaurantsList: ReactChild[] = []
  if (!filteredRestaurants || filteredRestaurants.length === 0) {
    for (var i = 0; i < 5; i++) restaurantsList.push(<Card loading />)
  } else {
    restaurantsList = filteredRestaurants.map((restaurant, index) => (
      <Card data={restaurant} key={index} />
    ))
  }

  return (
    <>
      <div className="row subtitleRow">
        <div className="subtitle">Reviews</div>
        <div className="row">
          <Select
            options={locationOptions}
            defaultValue={locationOptions[0].value}
            onValueChange={(val: string) => sortValues(val, 'location')}
          />
          <Select
            options={TierSelectOptions}
            defaultValue={TierSelectOptions[0].value}
            style={{ marginLeft: 'var(--space-3)' }}
            onValueChange={(val: string) => sortValues(val, 'tier')}
          />
        </div>
      </div>
      <div className={classNames('scrollSection', 'reviewsList')}>{restaurantsList}</div>
    </>
  )
}

export default ReviewsTemplate
